import facebookConversionsAPI from '../tools/facebookConversionsApi'; //轉換API
import axios from 'axios';

$(function(){
    new products();
    new IndexOverflowXScroll();
})

class products {
    
    // 建構值
    constructor() {
        this.fb = new facebookConversionsAPI();
        this.metaIdAndTokenExist = this.fb.checkIsMetaIdAndToken();
        this.productCurrency = 'TWD';
        this.init();
    }

    // 初始化
    init() {
        this.bindMetaApiEvents()
    }

    // 監聽事件: meta Pixel & 轉換 API 事件
    bindMetaApiEvents() {

        // 如果有 fb pixel id & 權杖 才發送資料
        if ( this.metaIdAndTokenExist == true ) {

            //加入購物車時，將該商品與用戶資料一起傳送 
            $('body').on('click', '.addToCartButton', (e) => {
                // 獲取當前按鈕並傳送
                const button = $(e.currentTarget);
                this.getUserData()
                    .then(userData => {
                        this.getAddToCartData(button, userData);
                    });              
            });
        
        } else {
            console.log('no meta pixel ID & Access Token')
        }
    }

    //加入購物車時，將該商品與用戶資料一起傳送 ================================
    getAddToCartData(button, userData) {
        const productBox = button.closest('.productBox');
        const productIdText = productBox.find('input[name="productId"]').val().trim();

        // 優先獲取優惠價格
        const productPriceText = productBox.find('.js-sale-price').length > 0 ?
            productBox.find('.js-sale-price').text().trim() :
            productBox.find('.js-price').text().trim();

        const productId = parseInt(productIdText.replace(/[^\w\d]/g, '')); 
        const productPrice = parseInt(productPriceText.match(/NT\$(\d{1,3}(,\d{3})*(\.\d+)?)/)[1].replace(/,/g, ''));
        const addToCartProduct = [];

        addToCartProduct.push({
            id: productId,
            item_price: productPrice,
        });

        const addToCartData = {
            "contents": addToCartProduct,
            "currency": this.productCurrency,
        }

        console.log(userData, addToCartData);
        // // meta pixel
        // fbq('track', 'AddToCart', 
        //     { contents: addToCartProduct, currency: this.productCurrency }, 
        //     { eventID: 'event.id.AddToCart' });
        
        // // mete conversions api
        // this.fb.sendAddToCart(userData, addToCartData) // 事件發送成功後，延遲半秒跳轉至購物車
        //     .then(() => { // 成功後跳轉
        //         setTimeout(function() {
        //         window.location.href = 'cart-content/normal';
        //         }, 500);
        //     })
        //     .catch(error => { // 失敗後印出訊息後跳轉
        //         console.error('Error sending AddToCart event:', error);

        //         setTimeout(function() {
        //         window.location.href = 'cart-content/normal';
        //         }, 500);
        //     });
    };

    // 獲取當前使用者會員資料
    getUserData() {
        return new Promise((resolve, reject) => {
    
            // 如果用戶已經登錄，則發送 Axios 請求獲取用戶數據
            axios.get('/facebook-conversions-api/me')
            .then(response => {
            resolve(response.data);
            })
            .catch(error => {
            reject(error);
            });
        
        });
    };
}
class IndexOverflowXScroll {

    constructor() {
      // 初始化設定
      this.$content = $(".bestSellersProducts");
      this.$scrollLeft = $(".scroll-left");
      this.$scrollRight = $(".scroll-right");
      this.$scrollBtns = $('.scrollBtnContainer');
  
      // 绑定事件处理程序
      this.$scrollLeft.on("click", this.scrollLeft.bind(this));
      this.$scrollRight.on("click", this.scrollRight.bind(this));
      $(window).on('resize', this.checkScrollPosition.bind(this));
      this.$content.on("scroll", this.checkScrollPosition.bind(this));

      this.checkScrollPosition();
    }
  
    // 检查滚动位置和是否超出容器
    checkScrollPosition() {
      var scrollLeft = this.$content.scrollLeft();
      var containerWidth = this.$content.parent().width();
      var scrollWidth = this.$content[0].scrollWidth;
  
      // 检查是否超出容器
      var isOverflowing = scrollWidth > containerWidth;
  
      if (!isOverflowing) {
        // 根據情況顯示左右按鈕
        this.$scrollBtns.toggleClass('d-none');
      } else {
        // 到容器的最前或最後時，關閉指定按鈕
        this.$scrollLeft.prop("disabled", scrollLeft === 0);
        this.$scrollRight.prop("disabled", scrollLeft + containerWidth >= scrollWidth);
      }
    }
  
    // 左滚功能
    scrollLeft() {
      this.$content.animate({ scrollLeft: "-=100px" }, "slow");
    }
  
    // 右滚功能
    scrollRight() {
      this.$content.animate({ scrollLeft: "+=100px" }, "slow");
    }

}

// // overflow-x-scoll 按鈕
// $(function () {
//     // 只在首頁執行
//     if (window.location.pathname === "/") {
//         // 初始化設定
//         var $content = $(".bestSellersProducts");
//         var $scrollLeft = $(".scroll-left");
//         var $scrollRight = $(".scroll-right");
//         var $scrollBtns = $('.scrollBtnContainer');

//         // 設定左右按鈕是否啟用
//         function checkScrollPosition() {
//             var scrollLeft = $content.scrollLeft();
//             var containerWidth = $content.parent().width();
//             var scrollWidth = $content[0].scrollWidth;

//             // 检查是否超出容器
//             var isOverflowing = scrollWidth > containerWidth;

//             if (!isOverflowing) {
//                 // 根據情況顯示左右按鈕
//                 $scrollBtns.toggleClass('d-none');
//             }else{
//                 // 到容器的最前或最後時，關閉指定按鈕
//                 $scrollLeft.prop("disabled", scrollLeft === 0);
//                 $scrollRight.prop("disabled", scrollLeft + containerWidth >= scrollWidth);
//             }
//         }

//         // 改變視窗尺寸時檢查容器位置
//         $(window).on('resize', checkScrollPosition); 

//         // 滾動容器時檢查容器位置
//         $content.on("scroll", checkScrollPosition);

//         // 載入視窗時檢查容器位置
//         checkScrollPosition();

//         // 左右滾動功能
//         $scrollLeft.on("click", function () {
//             $content.animate({ scrollLeft: "-=100px" }, "slow");
//         });

//         $scrollRight.on("click", function () {
//             $content.animate({ scrollLeft: "+=100px" }, "slow");
//         });
//     }
// });
